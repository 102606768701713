<template>
  <div class="container">
    <div class="header"><span class="line"></span> 灵活用工 <span style="color:orange;font-size:14px;margin-left:10px">注：数据来源于分时领英</span></div>
    <div class="nav">
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="任务名称" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.title" placeholder="请输入任务名称"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="结算类型" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-select style="width: 180px" placeholder="请选择" v-model="queryParam.payMode">
                <a-select-option value="10">单次结算</a-select-option>
                <a-select-option value="20">多次结算</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <!-- <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="入驻时间" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-range-picker style="width:230px" @change="onChange" v-model="date" />
            </a-form-item>
          </a-col> -->
          <a-col :lg="8" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="binding(record)">灵活用工</a>
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="灵活用工"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="服务日期">
          <a-range-picker
            placeholder="服务日期"
            v-decorator="['date', { rules: [{ required: true, message: '请选择服务日期!' }] }]"
          />
        </a-form-item>
        <a-form-item label="管理用户">
          <a-select style="width: 100%" placeholder="请选择管理用户" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompany" @change="handleChangeSelectCompany" v-decorator="['relationId', {rules:[{ required: true, message: '请选择管理用户' }]}]">
            <a-select-option v-for="item in scopeRateList" :key="item.userId" :value="item.userId">{{item.userName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务门店">
          <a-select style="width: 100%" placeholder="请选择服务门店" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompanyRask" mode="multiple" @change="handleChangeSelectCompanyRask" v-decorator="['merchantIds', {rules:[{ required: true, message: '请选择服务门店', type: 'array' }]}]">
            <a-select-option v-for="item in storeList" :key="item.id" :value="item.id">{{item.fullName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="提成比例">
          <a-input
            placeholder="请填写提成比例"
            type="number"
            suffix="%"
            v-decorator="['rebatePoint', { rules: [{ required: true, message: '请填写提成比例!' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import moment from 'moment'
import {
  enterpriseMerchantList,
  raskList,
  taskWorker,
  merchantServiceConfig,
  getServiceMerchantBox
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '任务名称',
          dataIndex: 'title',
        },
        {
          title: '任务开始日期',
          dataIndex: 'workBeginDate',
          customRender: text => text.split(' ')[0]
        },
        {
          title: '任务结束日期',
          dataIndex: 'workEndDate',
          customRender: text => text.split(' ')[0]
        },
        {
          title: '预算金额',
          dataIndex: 'budgetAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '预计人数',
          dataIndex: 'needNum',
        },
        {
          title: '报名人数',
          dataIndex: 'applyNum',
        },
        {
          title: '用工人数',
          dataIndex: 'signNum',
        },
        {
          title: '结算类型',
          dataIndex: 'payMode',
          customRender: text => text==='10'? '单次结算' : '多次结算',
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text==='13'? '报名中' : text==='20'? '进行中' : text==='14'? '报名截止' : text==='30'? '已完成' : text==='50'? '已取消' : '--',
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        title: '',
      },
      date: [],
      scopeRateList: [],
      storeList: [],
      record: '',
      relationWorkerId: ''
    }
  },
  methods: {
    // 员工
    handleChangeSelectCompany (value) {
      this.scopeRateList.forEach(item=>{
        if (value===item.userId) {
          this.relationWorkerId = item.id
        }
      })
    },
    handleSearchSelectCompany (value) {
      
    },
    // 任务
    handleChangeSelectCompanyRask (value) {
      
    },
    handleSearchSelectCompanyRask (value) {
      getServiceMerchantBox().then(res =>{
        if (res.success) {
          this.storeList = res.result
        }
      })
    },
    // binding
    binding (record) {
      window.sessionStorage.setItem('record',JSON.stringify(record))
      window.localStorage.setItem('submenu','flexibleManageDetail')
      this.$router.push('/flexibleManageDetail')
      // this.record = record
      // taskWorker({taskId:record.id,signStatus:'30'}).then(res => {
      //   if (res.success) {
      //     this.scopeRateList = res.result.data
      //   }
      // })
      // getServiceMerchantBox().then(res =>{
      //   if (res.success) {
      //     this.storeList = res.result
      //   }
      // })
      // this.visibleAdd = true
    },
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.finishTime = dateString[1]
    },
    // 获取列表
    raskData (parameter) {
      return raskList(Object.assign(parameter,this.queryParam,{payTag:'1',payMode:'20'})).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        title: '',
      };
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          let params = {
            merchantIds: values.merchantIds,
            rebatePoint:(values.rebatePoint/100).toFixed(2),
            serviceBeginDate:moment(values.date[0]).format('YYYY-MM-DD'),
            serviceEndDate:moment(values.date[1]).format('YYYY-MM-DD'),
            relationNo: this.record.id,
            relationNoTitle: this.record.title,
            relationName: '',
            relationId:values.relationId,
            relationWorkerId: this.relationWorkerId
          }
          this.scopeRateList.forEach(item=>{
            if (item.userId===values.relationId) {
              params.relationName=item.userName
            }
          })
          merchantServiceConfig(params).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.handleToSearchEnterprise()
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          }).catch(()=>{this.confirmLoadingAdd = false})
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.scopeRateList = []
      this.storeList = []
      this.visibleAdd = false
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>